// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

import { PaletteOptions } from "@mui/material";
import { Colors } from "./colors";

export default function themePalette(colors: typeof Colors): PaletteOptions {
  return {
    mode: "light",
    primary: {
      main: colors.PrimaryMain,
      light: colors.PrimaryLight,
      dark: colors.PrimaryDark,
    },
    secondary: {
      light: colors.SecondaryLight,
      main: colors.SecondaryMain,
      dark: colors.SecondaryDark,
    },
    info: {
      light: colors.InfoLight,
      main: colors.InfoMain,
      dark: colors.InfoDark,
      contrastText: colors.InfoContrast,
    },
    text: {
      primary: colors.TextPrimary,
      secondary: colors.TextSecondary,
      disabled: colors.TextDisabled,
    },
    error: {
      light: colors.ErrorLight,
      main: colors.ErrorMain,
      dark: colors.ErrorDark,
    },
    warning: {
      light: colors.WarningLight,
      main: colors.WarningMain,
      dark: colors.WarningDark,
    },
    success: {
      light: colors.SuccessLight,
      main: colors.SuccessMain,
      dark: colors.SuccessDark,
    },
    grey: {
      A100: colors.GreyA100,
      A200: colors.GreyA200,
      A400: colors.GreyA400,
      A700: colors.GreyA700,
    },
    // divider: colors.Divider,
  };
}
