// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

export enum Colors {
  ErrorDark = "#97192F",
  ErrorLight = "#F19683",
  ErrorTint = "#FEF3F2",
  ErrorMain = "#D33232",

  WarningDark = "#A84B02",
  WarningLight = "#F8C266",
  WarningTint = "#FEF6EE",
  WarningMain = "#EA7F04",

  InfoContrast = "#FFFFFF",
  InfoDark = "#1978A6",
  InfoLight = "#94D6F5",
  InfoMain = "#29ADEB",

  SuccessDark = "#217D0A",
  SuccessLight = "#A5E76D",
  SuccessTint = "#ECFDF3",
  SuccessMain = "#3A8D11",
  SuccessBackground = "#E8FBCE",

  PrimaryContrast = "#FFFFFF",
  PrimaryVeryDark = "#070628",
  PrimaryDark = "#3D0F58",
  PrimaryLight = "#BC9DD4",
  PrimaryMain = "#551B76",
  PrimaryContainerDark = "#240F38",
  PrimaryContainerDarkAccent = "#190C26",

  PrimaryAccentSkyBlue = InfoMain,
  PrimaryAccentGreen = "#17B26A",
  PrimaryAccentBlue = "#2E90FA",
  PrimaryAccentViolet = "#6938EF",
  PrimaryAccentPeriwinkle = "#687AE9",
  PrimaryAccentBackground = "#F9F9FB",
  PrimaryAccentBackgroundLight = "#ECEEF6",
  PrimaryAccentAi = "#826BFF",

  SecondaryDark = "#CCD0D5",
  SecondaryLight = "#F5F6F7",
  SecondaryMain = "#E8EAED",

  TextDisabled = "#A4AAAD",
  TextPrimary = "#1C2B33",
  TextSecondary = "#727A92",
  TextContent = "#ADBDCC",
  TextDark = "#000000",

  HeadingText = "#23315A",
  BodyText = "#727A92",

  BackgroundBody = "#F6F9FC",
  BackgroundBodyActive = "#EEF0F3",
  BackgroundFooter = "#260C3B",
  BannerBackground = "#05172C",

  BorderDefault = "#DADCE0",
  BorderDark = "#CDD2DB",

  GreyA100 = "#EAE6FF",
  GreyA200 = "#BFB6EE",
  GreyA400 = "#3D338F",
  GreyA700 = "#231E52",
  GreyB900 = "#263238",

  GreyDisabled = "#E0E1E4",
  IconDisabled = "#828AA4",

  LightBlue = "#EAF7FD",
  BlueGrey = "#ECEFF1",

  heroShadow = "0px 0px 72px 0px rgb(23 46 103 / 50%)",
  mainShadow = "0px 12px 42px 0px rgb(23 46 103 / 20%)",
  featureShadow = "0px 0px 48px rgba(41, 173, 235, 0.08), 0px 38px 48px -20px rgba(50, 50, 93, 0.17)",
  cardShadow = "0px 0px 48px rgba(41, 173, 235, 0.08)",

  HeroText = "#F5F9FC",
  HeroSubText = "#D7DEE3",

  HomeBodyText = "#0C0A30",
  HomeSubText = "#7F7F7F",
  HomeHeroText = "#E8EBED",

  ContainerOutline = "#DDDDDD",
  ContentHighlight = "#F5F9FC",

  CodeEditorBackground = "#0b1a2d",
}
