// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

import { createTheme } from "@mui/material";
import { Theme, ThemeOptions } from "@mui/system";
import { deepmerge } from "@mui/utils";

import { Colors } from "./colors";
import components, { AllComponents } from "./components";
import themePalette from "./palette";
import { createBreakpoints } from "@mui/system";

const themeFonts = () => {
  return {
    fontFamily: ["Kanit", "sans-serif"].join(","),
  };
};

export function getThemeOptions(): { components: AllComponents } {
  return {
    components: components(Colors),
  };
}

export const getPaletteThemeOptions = () => {
  return {
    palette: themePalette(Colors),
  };
};

export const getTheme = (
  options: { themeOverride?: ThemeOptions } = {},
): Theme => {
  const theme_ = createTheme(
    deepmerge(
      {
        palette: themePalette(Colors),
        components: components(Colors),
        typography: themeFonts(),
      },
      options.themeOverride,
    ),
  );

  return theme_;
};

const breakpoints = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1236,
      ll: 1320,
      xl: 1536,
    },
  },
};
const theme = getTheme({ themeOverride: { ...breakpoints } });

export default theme;

export const getTwentyFourTheme = (theme: any) => {
  return createTheme({
    ...theme,
    breakpoints: createBreakpoints({
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    }),
    typography: {
      ...theme.typography,
      fontFamily: "Inter",
      allVariants: {
        ...theme.typography.allVariants,
        fontFamily: "Inter",
      },
    },
    components: {
      MuiButton: {
        ...theme.components.MuiButton,
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
        },
        variants: [
          {
            props: { variant: "containedInverse" },
            style: {
              ...theme.components?.MuiButton?.styleOverrides?.contained,
              color: Colors.PrimaryMain,
              backgroundColor: Colors.PrimaryContrast,
              borderRadius: "8px",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Inter",
              padding: "0px 32px",
              height: "46px",
            },
          },
        ],
        styleOverrides: {
          ...theme.components?.MuiButton?.styleOverrides,
          fontFamily: "Inter",
          root: {
            ...theme.components?.MuiButton?.styleOverrides?.root,
            color: Colors.PrimaryContrast,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            padding: "0px 32px",
            height: "46px",
          },
          contained: {
            ...theme.components?.MuiButton?.styleOverrides?.contained,
            color: Colors.PrimaryContrast,
            borderRadius: "8px",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            padding: "0px 32px",
            height: "46px",
          },
        },
      },
      MuiTypography: {
        ...theme.components.MuiTypography,
        defaultProps: {
          ...theme.components.MuiTypography.defaultProps,
        },
        styleOverrides: {
          ...theme.components.MuiTypography.styleOverrides,
          h1: {
            ...theme.components.MuiTypography.h1,
            fontFamily: "Kanit",
            fontWeight: 500,
            fontSize: "70px",
            letterSpacing: "-1.75px",
            lineHeight: "150%",
            color: Colors.TextPrimary,
          },
          h2: {
            ...theme.components.MuiTypography.h2,
            fontFamily: "Kanit",
            fontWeight: 600,
            lineHeight: "50px",
            fontSize: "48px",
            [theme.breakpoints.up("md")]: {
              fontSize: "56px",
              lineHeight: "56px",
            },
            color: Colors.TextPrimary,
          },
          h3: {
            ...theme.components.MuiTypography.h3,
            fontFamily: "Kanit",
            fontSize: "44px",
            lineHeight: "46px",
            [theme.breakpoints.up("sm")]: {
              fontSize: "48px",
              lineHeight: "50px",
            },
            color: Colors.TextPrimary,
          },
          h4: {
            ...theme.components.MuiTypography.h4,
            fontFamily: "Kanit",
            fontSize: "19px",
            lineHeight: "150%",
            color: Colors.TextPrimary,
            fontWeight: 500,
          },
          h5: {
            ...theme.components.MuiTypography.h5,
            fontFamily: "Kanit",
            lineHeight: "150%",
            color: Colors.TextPrimary,
          },
          h6: {
            ...theme.components.MuiTypography.h6,
            fontFamily: "Kanit",
            lineHeight: "150%",
            color: Colors.TextPrimary,
          },
          subtitle1: {
            ...theme.components.MuiTypography.subtitle1,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            letterSpacing: 0,
          },
          subtitle2: {
            ...theme.components.MuiTypography.subtitle2,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            letterSpacing: 0,
          },
          body1: {
            ...theme.components.MuiTypography.body1,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
            lineHeight: "175%",
            letterSpacing: 0,
            color: Colors.BodyText,
          },
          body2: {
            ...theme.components.MuiTypography.body2,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: 0,
            color: Colors.BodyText,
          },
          caption: {
            ...theme.components.MuiTypography.caption,
            fontFamily: "Inter",
            textTransform: "uppercase",
            color: Colors.HomeBodyText,
            fontSize: "12px",
            [theme.breakpoints.up("md")]: {
              fontSize: "14px",
            },
            fontWeight: 500,
            letterSpacing: 2.8,
          },
          button: {
            ...theme.components.MuiTypography.button,
            color: Colors.PrimaryContrast,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "200%",
            textTransform: "capitalize",
          },
          overline: {
            ...theme.components.MuiTypography.overline,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: "1.25rem",
            letterSpacing: "0.0625rem",
            textTransform: "uppercase",
          },
        },
      },
    },
  });
};
