// Copyright 2021 Pangea Cyber Corporation
// Author: Pangea Cyber Corporation

import { Components, createTheme, withTheme } from "@mui/material/styles";
import { Colors } from "./colors";

const defaultTheme = createTheme();

export interface AllComponents extends Components {}

export default function componentStyleOverrides(
  colors: typeof Colors,
): AllComponents {
  return {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "inherit",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          padding: "7px 16px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          textTransform: "none",

          "&.light": {
            color: Colors.PrimaryContrast,
            borderColor: Colors.PrimaryContrast,

            "&:hover": {
              color: Colors.SecondaryMain,
              borderColor: Colors.SecondaryMain,
            },
          },

          "&.MuiButton-outlinedSecondary": {
            color: Colors.PrimaryMain,
            borderColor: Colors.PrimaryMain,
          },
        },
        contained: {
          padding: "7px 16px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          textTransform: "none",

          "&.light:hover": {
            color: Colors.PrimaryMain,
            backgroundColor: Colors.SecondaryLight,
          },
          "&:hover, &:visited, &:active, &:target": {
            color: Colors.PrimaryContrast,
          },
          "&.MuiButton-containedSecondary": {
            color: Colors.TextPrimary,

            "&:hover, &:visited, &:active, &:target": {
              color: Colors.TextPrimary,
            },
          },
        },
        text: {
          padding: "7px 16px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          textTransform: "none",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "16px",
          marginBottom: "0",

          "&$focused": {
            color: Colors.HeadingText,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: Colors.BorderDefault,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "[opacity='.3']": {
            fill: Colors.InfoMain,
          },
          "&.disabled": {
            color: Colors.IconDisabled,
            fill: "currentColor",
            "path[opacity='.3']": {
              fill: Colors.IconDisabled,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "8px 12px",
          fontWeight: "300",
          verticalAlign: "top",
          "& p": {
            margin: "8px 0",
          },
        },
        head: {
          fontWeight: "500",
          color: Colors.TextSecondary,
          textTransform: "uppercase",
          "&:first-child": {
            borderRadius: "8px 0 0 8px",
          },
          "&:last-child": {
            borderRadius: "0 8px 8px 0",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 600,
          fontSize: "2.5rem",
          lineHeight: "3rem",
          letterSpacing: "-0.09375rem",
          color: Colors.TextPrimary,
        },
        h2: {
          fontWeight: 600,
          fontSize: "2rem",
          lineHeight: "2.375rem",
          color: Colors.TextPrimary,
        },
        h3: {
          fontWeight: 500,
          fontSize: "32px",
          lineHeight: "36px",
          letterSpacing: "-1.5px",
        },
        h5: {
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "32px",
          letterSpacing: "0.25px",
          color: Colors.HeadingText,
        },
        h6: {
          fontWeight: 300,
          fontSize: "1.125rem",
          lineHeight: "1.5rem",
          color: Colors.TextPrimary,
        },
        body1: {
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "24px",
          color: Colors.TextSecondary,
        },
        body2: {
          fontWeight: 300,
          fontSize: "17px",
          lineHeight: "25px",
          color: Colors.TextSecondary,
        },
        caption: {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "16px",
          color: Colors.TextSecondary,
        },
        overline: {
          fontWeight: 500,
          fontSize: "15px",
          lineHeight: "24px",
          color: Colors.InfoMain,
          textTransform: "none",
        },
      },
    },
  };
}
